import {
  buttons,
  colors,
  fonts,
  links,
  text,
  pageContent,
} from '../vars';

const theme = {
  global: {
    fontFamily: fonts.atc.fontFamily,
    pageContent: {
      backgroundColor: colors.global.white1,
      border: pageContent.global.border,
      borderRadius: pageContent.global.borderRadius,
    },
  },
  header: {
    blankBackgroundColor: colors.atc.blue1,
    backgroundColor: colors.atc.blue1,
    color: colors.global.white1,
    fontSize: '22px',
    textTransform: 'initial',
  },
  keyline: {
    borderColor: colors.global.gray7,
  },
  button: {
    fontWeight: 'bold',
    fontSize: '14px',
    color: colors.global.white1,
    borderRadius: '4px',
    background: buttons.atc.background,
    height: '37px',
    width: '100%',
    border: '1px solid transparent',
    cursor: 'pointer',
    justifyContent: 'end',
    desktopGridColumn: '10 / end',
    textTransform: 'initial',
    hover: {
      background: buttons.atc.hoverBackground,
    },
  },
  text: {
    color: colors.global.blue4,
    subtitle: colors.global.blue2,
  },
  box: {
    backgroundColorDisabled: colors.global.gray6,
    border: `1px solid ${colors.global.gray1}`,
    borderColor: `${colors.global.gray1}`,
    borderRadius: '3px',
    baseGlowingColor: colors.global.blue2,
    errorGlowingColor: colors.global.red1,
    height: '40px',
    lineHeight: '20px',
  },
  description: {
    title: {
      fontSize: '18px',
      color: colors.global.blue4,
    },
    subtitle: {
      color: colors.global.blue2,
    },
  },
  changeZipButton: {
    color: colors.global.blue1,
  },
  vehicleInfo: {
    vehicleModel: {
      color: colors.global.blue2,
    },
    vehicleTrim: {
      color: colors.global.blue4,
    },
    changeVehicleLink: {
      color: colors.global.blue1,
    },
  },
  keylineShadow: `0 20px 20px -20px ${colors.global.gray7}`,
  // step 1
  // step 2
  dealerSelectorView: {
    dealerBlock: {
      checkboxBackgroundColor: colors.global.blue5,
      dealershipInfo: {
        color: colors.global.blue2,
        distanceDiv: {
          color: colors.global.gray4,
        },
      },
    },
    pagination: {
      pageItem: {
        fontSize: '14px',
      },
      fontWeight: '400',
      selectedPage: {
        backgroundColor: colors.atc.blue1,
        color: colors.global.white1,
      },
      notSelectedPage: {
        backgroundColor: 'transparent',
        color: colors.global.black1,
      },
    },
  },
  // step 3
  contactFormView: {
    disclaimer: {
      disclaimerText: text.atc.contactFormView.disclaimer.disclaimerText,
      linkText: text.atc.contactFormView.disclaimer.linkText,
      linkTextDecoration: 'underline',
      disclaimerLinkUrl: links.atc.disclaimerLinkUrl,
    },
  },
  // step 4
  thankYouPage: {
    checkMarkColor: colors.global.green1,
    ATBreakLineColor: colors.atc.white1,
    ATBtnBorderColor: colors.atc.blue2,
    ATBtnBackgroundColor: colors.atc.blue3,
  },
};

export default theme;
